var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.isArtist
        ? _c("nav-top", {
            attrs: { list: _vm.nav.list, "current-key": _vm.nav.currentKey }
          })
        : _vm._e(),
      _c(
        "ul",
        {
          ref: "container",
          staticClass: "qna-list",
          on: { scroll: _vm.onScroll }
        },
        [
          _vm._l(_vm.nav.list, function(item, itemKey) {
            return [
              _vm.nav.currentKey === itemKey
                ? [
                    !_vm.init || _vm.qna.list[_vm.nav.currentKey].length
                      ? [
                          _vm._l(_vm.qna.list[_vm.nav.currentKey], function(
                            row
                          ) {
                            return [
                              _c(
                                "div",
                                { key: itemKey + ":" + row.uid },
                                [
                                  _c("qna-item", { attrs: { row: row } }),
                                  _c("div", {
                                    staticClass: "ui-border-line",
                                    staticStyle: { height: "10px" }
                                  })
                                ],
                                1
                              )
                            ]
                          })
                        ]
                      : _c("blank-search", {
                          key: itemKey,
                          attrs: {
                            text: _vm.isArtist
                              ? "등록된 문의가 없습니다.\n더 활발히 활동해볼까요?"
                              : "등록한 문의가 없습니다."
                          }
                        })
                  ]
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }