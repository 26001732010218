<template>
    <div class="container">
        <nav-top
            v-if="isArtist"
            :list="nav.list"
            :current-key="nav.currentKey"
            />

        <ul class="qna-list" ref="container" @scroll="onScroll">
            <template v-for="(item, itemKey) in nav.list">
                <template v-if="nav.currentKey === itemKey">
                    <template v-if="!init || qna.list[nav.currentKey].length">
                        <template v-for="(row) in qna.list[nav.currentKey]">
                            <div :key="`${itemKey}:${row.uid}`">
                                <qna-item :row="row" />
                                <div class="ui-border-line" style="height: 10px"></div>
                            </div>
                        </template>
                    </template>
                    <blank-search :key="itemKey" v-else :text="isArtist ? '등록된 문의가 없습니다.\n더 활발히 활동해볼까요?' : '등록한 문의가 없습니다.'" />
                </template>
            </template>
        </ul>
    </div>
</template>

<script>
import QnaItem from '@/components/etc/QnaItem'
import BlankSearch from '@/components/blank/BlankList'
import NavTop from '@/components/common/NavTop'

export default {
    name: 'Qna',
    components: {
        QnaItem,
        BlankSearch,
        NavTop,
    },
    data() {
        let navBtnCb = (key = 0) => {
            this.qna.que = this.qna.que.map(() => false)
            this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
            this.nav.currentKey = key
            this.$nextTick()
                .then(() => {
                    this.$refs?.container?.scroll?.({ top: this.scroll.lastY[this.nav.currentKey] || 0 })
                    this.getQnaData(this.qna.list[this.nav.currentKey].length === 0)
                })
        }
        return {
            init: false,
            qna: {
                list: [[],[]],
                offset: [0, 0],
                que: [false, false],
            },
            scroll: {
                lastY: [0, 0],
            },
            nav: {
                list: [
                    {
                        label: '미답변',
                        action: () => navBtnCb(0),
                        reply: 'n',
                    },
                    {
                        label: '답변 완료',
                        action: () => navBtnCb(1),
                        reply: 'y',
                    },
                ],
                currentKey: 0,
            },
            history: {
                exit: false,
            },
            events: {
                root: {},
            },
        }
    },
    computed: {
        userData() {
            return this.$store?.state?.user?.userData
        },
        isArtist() {
            return this.userData?.category === 'artist'
        },
        isClient() {
            return this.userData?.category === 'client'
        },
    },
    created() {
        this.events.root['qna:reload'] = () => {
            this.getQnaData(true)
        }
        this.events.root['qna:reply'] = (uid, reply) => {
            if (uid && reply) {
                let qnaIndex = this.qna?.list?.findIndex?.(row => row.uid === uid)

                if (qnaIndex !== -1) {
                    this.qna.list[this.nav.currentKey][qnaIndex].reply = reply
                    this.qna.list[this.nav.currentKey][qnaIndex].replyDate = Date.now().toString().slice(0, 10)

                    if (this.isArtist && this.nav.currentKey === 0) {
                        let qna = this.qna.list[0].splice(qnaIndex, 1)
                        this.qna.list[1].unshift(qna)
                    }
                } else {
                    for (let key = 0, len = this.qna.list.length; key < len; key++) {
                        this.qna.list[key] = []
                    }
                    this.getQnaData(true)
                }
            }
        }

        for (const key in this.events.root) {
            if (this.events.root.hasOwnProperty(key)) {
                const cb = this.events.root[key]
                this.$root.$on(key, cb)
            }
        }

        this.$eventBus.$on('refresh', route => {
            if (route.path === '/etc/qna') {
                this.$nextTick()
                    .then(() => {
                        this.$refs?.container?.scroll?.({ top: 0 })
                    })
                    .then(() => {
                        this.getQnaData(true)
                    })
            }
        })

        this.getQnaData()
    },
    activated() {
        this.$refs?.container?.scroll?.({ top: this.scroll?.lastY || 0 })
    },
    deactivated() {
        this.scroll.lastY[this.nav.currentKey] = this.$refs?.container?.scrollTop || 0
    },
    beforeRouteEnter(to, from, next) {
        next(Vue => {
            Vue.$store.commit('setGnbTitle', Vue.isClient ? '문의 내역' : '문의 내역')
            if (!Vue.init) {
                Vue.getQnaData(true)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        setTimeout(() => {
            if (this.$store?.state?.history?.back) {
                this.$runToApp({
                    androidCb: () => {
                        if (!this.history.exit) {
                            this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
                            this.history.exit = true
                            setTimeout(() => (this.history.exit = false), 2000)
                        } else {
                            location.href = 'hbscheme://appExit'
                        }
                        next(false)
                    },
                    iosCb: () => next(false),
                    webCb: () => next(false),
                })
            } else {
                this.$store.commit('setGnbTitle', null)
                next()
            }
        })
    },
    beforeDestroy() {
        for (const key in this.events.root) {
            if (this.events.root.hasOwnProperty(key)) {
                const cb = this.events.root[key]
                this.$root.$off(key, cb)
            }
        }
    },
    methods: {
        getQnaData(init) {
            if (this.qna.que[this.nav.currentKey]) return
            this.qna.que[this.nav.currentKey] = true

            if (init) {
                this.init = false
                this.qna.offset[this.nav.currentKey] = 0
            }

            const req = {
                method: 'get',
                url: `/${this.userData.category}/offer/contact/${this.qna.offset[this.nav.currentKey]}/20`,
            }

            if (this.isArtist) {
                req.params = {
                    reply: this.nav.list[this.nav.currentKey].reply,
                }
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let qna = data?.response?.artistContactData || []

                        this.qna.list[this.nav.currentKey] = init ? qna : this.qna.list[this.nav.currentKey].concat(qna)
                        this.qna.offset[this.nav.currentKey] += qna.length

                    } else {
                        this.$alert(resultData)
                    }
                    return
                })
                .catch(e => {
                    log.error(e)
                    return
                })
                .then(() => {
                    this.init = true
                    setTimeout(() => {
                        this.qna.que[this.nav.currentKey] = false
                    }, 1000)
                    return
                })
        },
        onScroll() {
            if (['Qna'].indexOf(this.$route.name) === -1) return

            let scrollTop = this.$refs?.container?.scrollTop || 0
            let scrollHeight = this.$refs?.container?.scrollHeight || 0
            let clientHeight = this.$refs?.container?.clientHeight || 0

            if (scrollHeight <= scrollTop + clientHeight) {
                this.getQnaData()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
#page > .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    .qna-list {
        padding: 0 3rem;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
